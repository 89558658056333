import React from "react"

export default function ServerCard({ server }) {
  const clipboard =
    typeof window !== "undefined" &&
    window.navigator &&
    window.navigator.clipboard

  const {
    ServerName: name,
    ServerIP: ip,
    ServerPort: port,
    PlayerCount: players,
    IngameTime: time,
    CurrentMap: map,
    GameMode: mode,
    ForkName: fork,
    BuildVersion: build,
    WinDownload: win,
    OSXDownload: osx,
    LinuxDownload: linux,
  } = server

  return (
    <li className="md:mx-auto md:w-2/3 max-w-3xl my-4">
      <div className="bg-gray-700 p-6 pb-12">
        <h1 className="md:text-3xl">
          {name}{" "}
          <span>
            <small className="opacity-75">
              ({ip}:{port})
            </small>{" "}
            {clipboard != null && (
              <button
                type="button"
                className="text-sm hidden md:inline-block hover:underline align-middle hover:text-gray-400"
                onClick={() => {
                  clipboard.writeText(`${ip}:${port}`)
                }}
              >
                <i className="fas fa-clipboard" /> Copy to Clipboard
              </button>
            )}
          </span>
        </h1>
        <div className="flex flex-wrap md:w-2/3 justify-between">
          <div className="flex-initial bg-gray-800 px-1 mr-2">
            <i className="fas fa-users" /> {players}
          </div>
          <div className="flex-initial bg-gray-800 px-1 mr-2">
            <i className="fas fa-stopwatch" /> {time}
          </div>
          <div className="flex-initial text-gray-400">
            Playing <span className="text-salmon">{mode}</span> on{" "}
            <span className="text-salmon">{map}</span>
          </div>
          <div className="flex-initial mt-2">
            <i className="fas fa-code-branch" /> {fork}#{build}
          </div>
        </div>
      </div>
      <div className="lg:mx-auto lg:w-5/6 bg-gray-800 md:-mt-6 p-2">
        <h3 className="block lg:hidden text-teal-200">Download</h3>
        <div className="flex lg:text-2xl">
          <div className="hidden lg:block flex-initial text-teal-200 text-right">
            <small>Download</small>
          </div>
          <DownloadLink href={win}>
            <i className="fab fa-windows" /> Windows
          </DownloadLink>

          <DownloadLink href={osx}>
            <i className="fab fa-apple" /> OSX
          </DownloadLink>

          <DownloadLink href={linux}>
            <i className="fab fa-linux" /> Linux
          </DownloadLink>
        </div>
      </div>
    </li>
  )
}

function DownloadLink({ href, children }) {
  if (!href) return null

  return (
    <div className="flex-initial sm:flex-1 text-center mx-1">
      <a
        href={href}
        className="sm:block border-teal-400 hover:border-teal-300 text-teal-400 bg-white hover:bg-gray-300 px-1 hover:text-teal-600 rounded"
        target="_blank"
        rel="noopener noreferrer"
      >
        {children}
      </a>
    </div>
  )
}
