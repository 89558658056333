import React from "react"
import ErrorPanel from "./error-panel"
import ServerCard from "./server-card"
import useFetch from "./use-fetch"

export default function ServerList({ uri }) {
  const { data, error, refetch } = useFetch(uri, {
    pollInterval: 5000,
  })

  if (error) {
    return <ErrorPanel error={error} onDismiss={refetch} />
  }

  return (
    <ul className="list text-white">
      {data != null &&
        data.servers.map((server, index) => (
          <ServerCard key={index} server={server} />
        ))}
    </ul>
  )
}
