import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ServerList from "../components/server-list"

export default function Servers() {
  return (
    <Layout>
      <SEO title="Server List" />
      <h2 className="text-4xl text-center text-teal-600">Server List</h2>
      <ServerList uri="https://api.unitystation.org/serverlist" />
    </Layout>
  )
}
