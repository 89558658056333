import { useEffect, useCallback, useReducer } from "react"

const initialState = {
  data: null,
  error: null,
  loading: true,
}

export default function useFetch(uri, { pollInterval } = {}) {
  const [state, dispatch] = useReducer(reducer, initialState)

  const makeFetch = useCallback(() => {
    dispatch({ type: "begin" })
    ;(async () => {
      try {
        const res = await fetch(uri)
        const json = await res.json()
        dispatch({ type: "success", payload: json })
      } catch (err) {
        dispatch({ type: "error", payload: err })
      }
    })()
  }, [uri])

  useEffect(() => {
    makeFetch()

    if (pollInterval > 0) {
      const handle = setInterval(makeFetch, pollInterval)
      return () => clearInterval(handle)
    }
  }, [makeFetch, pollInterval])

  return { ...state, refetch: makeFetch }
}

function reducer(state, action) {
  switch (action.type) {
    case "begin": {
      return { ...state, loading: true }
    }
    case "success": {
      return { data: action.payload, loading: false, error: null }
    }
    case "error": {
      return { ...state, error: action.payload, loading: false }
    }
    default:
      throw new Error(`Unexpected type: ${action.type}`)
  }
}
